export const checkForSingleFilter = (filterMap) => {
	const appliedFilters = Object.values(filterMap).filter((f) => f.length === 1);
	const singleFilterIsApplied = appliedFilters.length === 1;

	if (singleFilterIsApplied) {
		return appliedFilters[0][0];
	}

	return null;
};

export const checkForMultiFilter = (filterMap) => {
	const appliedFilters = Object.values(filterMap).reduce((data, filter) => {
		return filter.length > 0 ? data.concat(filter) : data;
	}, []);

	const multiFilterIsApplied = appliedFilters.length > 1;
	if (multiFilterIsApplied) {
		return appliedFilters;
	}

	return null;
};

export const getAppliedFilterIds = (categoryId, filters) => {
	const facetIds = [categoryId];

	// Get facet ids to send to search API
	const filterNames = Object.keys(filters);
	filterNames.forEach((filterName) => {
		const filter = filters[filterName];
		if (filter.length) {
			filter.forEach((facet) => facetIds.push(facet.id));
		}
	});

	return facetIds;
};
