import { tkCanonicalHost } from '../../settings';
import { checkForMultiFilter, checkForSingleFilter } from '../filters';
import searchFacetSeoText from './searchFacetSeoText';

export const seoFilter = (categoryCode, filterMap, useMeta = false) => {
	const filter = checkForSingleFilter(filterMap);
	const multiFilter = checkForMultiFilter(filterMap);

	let seoOptimization = [];
	if (filter) {
		seoOptimization = searchFacetSeoText.filter(
			(facet) =>
				facet.category_code === categoryCode && filter.id === facet.filter_id,
		);
	} else if (multiFilter) {
		seoOptimization = searchFacetSeoText.filter(
			(facet) =>
				facet.category_code === categoryCode &&
				Array.isArray(facet.filter_id) &&
				multiFilter.every((filter) => facet.filter_id.includes(filter.id)),
		);
	}

	if (seoOptimization.length > 0) {
		return useMeta
			? seoOptimization[0].meta_text || seoOptimization[0].seo_text
			: seoOptimization[0].seo_text;
	}

	return undefined;
};

const facetsToRemove = new Set([
	'boudoir',
	'ceremony-accessories',
	'cruises',
	'ceremony-accessory',
	'cruise',
	'current-winner',
	'design',
	'drone',
	'drones',
	'flower-girl-basket',
	'flower-girl-baskets',
	'hall-of-fame',
	'marriage-proposal-planner',
	'marriage-proposal-planners',
	'on-site-wedding-hair-makeup',
	'plant',
	'plants',
	'wedding-bartender',
	'wedding-bartenders',
	'wedding-boutonniere',
	'wedding-boutonnieres',
	'wedding-calligraphies',
	'wedding-calligraphy',
	'wedding-centerpiece',
	'wedding-centerpieces',
	'wedding-corsage',
	'wedding-corsages',
	'wedding-design',
	'wedding-flower-delivery-setup',
	'wedding-makeup',
]);

export const shouldRemoveFacet = (path = '') => {
	const pathArray = path.split('/');
	if (pathArray.length === 0) {
		return false;
	}
	const lastItem = pathArray[pathArray.length - 1];

	return facetsToRemove.has(lastItem);
};

const excludedFacets = new Set(['sort', 'gatekeeper']);

export const canonicalSearchPath = (request, removeFacet) => {
	const { query, path } = request;
	const queryKeys = Object.keys(query);
	const queryFacets = queryKeys.filter((key) => !excludedFacets.has(key));
	const isMultiFacet =
		queryFacets.length > 2 ||
		queryFacets.some((key) => query[key]?.split(' ').length > 1);
	const isVanityUrl = path.split('/').length > 3;

	if (
		queryKeys.includes('sort') ||
		removeFacet ||
		(!queryKeys.includes('page') && !isVanityUrl) ||
		isMultiFacet
	) {
		return path.split('/').slice(0, 3).join('/');
	}

	let queryString;
	const canonicalParams = Object.keys(query)
		.filter((key) => !(key === 'page' && query[key] === '1'))
		.filter((key) => !(key === 'offset' && query[key] === '0'));

	if (!Object.keys(canonicalParams).length) {
		queryString = '';
	} else {
		queryString = `?${canonicalParams
			.map((key) => `${key}=${query[key]}`)
			.join('&')}`;
	}

	return path.concat(queryString);
};

export const canonicalSearchUrl = (request, removeFacet) => {
	const path = canonicalSearchPath(request, removeFacet);
	return canonicalUrl(path);
};

export const canonicalUrl = (path) => {
	return `${tkCanonicalHost}${path}`;
};

export const composeCanonicalUrlForPath = (path) =>
	`${tkCanonicalHost}/marketplace/${path}`;

export const composeStorefrontCanonicalUrl = (relativeUri) =>
	`${tkCanonicalHost}${relativeUri}`;

export const previousRelLink = (request) => {
	const {
		query: { page },
	} = request;

	switch (page) {
		case undefined:
		case '1':
			return null;
		default:
			return canonicalSearchUrl({
				...request,
				query: {
					...request.query,
					page: `${Number(page) - 1}`,
				},
			});
	}
};

export const nextRelLink = (request, totalPages) => {
	const {
		query: { page },
	} = request;
	const newPage = page ? `${Number(page) + 1}` : '2';

	if (newPage > totalPages) {
		return null;
	}

	return canonicalSearchUrl({
		...request,
		query: {
			...request.query,
			page: newPage,
		},
	});
};

export const relLinks = (request, totalPages) => ({
	canonicalUrl: canonicalSearchUrl(request, shouldRemoveFacet(request?.path)),
	nextUrl: nextRelLink(request, totalPages),
	previousUrl: previousRelLink(request),
});
